import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  if (
    location.pathname === '/page/' ||
    location.pathname === '/page' ||
    location.pathname === '/page/contact/' ||
    location.pathname === '/page/contact' ||
    location.pathname === '/page/privacy/' ||
    location.pathname === '/page/privacy' ||
    location.pathname === '/page/terms/' ||
    location.pathname === '/page/terms'
  ) {
    return (
      <footer className="footer rd-upload rd-load">
        <div className="container footer__container">
          <div className="footer__left">
            <h2 className="footer__title tt-3">
              <img
                src="/logo.png"
                alt=""
                style={{ filter: 'invert(1)', width: 150 }}
              />
            </h2>
            <br />
            <a className="footer__link tx-17" href="mailto:info@hotelytrip.com">
              <img
                src="/mail.svg"
                data-rd-image="./img/svg/mail.svg"
                width={24}
                height={24}
                alt="mail"
              />
              info@hotelytrip.com
            </a>
          </div>
          <div className="footer__right">
            <div className="footer__right-top">
              <p className="footer__right-text tx-17">
                Planujte svoje potovanje z nami in odkrijte najboljša prenočišča
                z edinstvenimi ugodnostmi. Uživajte v luksuzu, udobju in
                nepozabnih izkušnjah na vsakem koraku.
              </p>
            </div>
            <div className="footer__right-bottom">
              <ul
                className="footer__list"
                style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}
              >
                <li className="footer__item">
                  <Link to="/page/privacy">
                    <a className="footer__item-link tx-17">Politika zasebnosti</a>
                  </Link>
                </li>
                <li className="footer__item">
                  <Link to="/page/terms">
                    <a className="footer__item-link tx-17">Pogoji uporabe</a>
                  </Link>
                </li>
                <li className="footer__item">
                  <Link to="/page/contact">
                    <a className="footer__item-link tx-17">Kontakt</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="footer rd-upload rd-load">
      <div className="container footer__container">
        <div className="footer__left">
          <h2 className="footer__title tt-3">
            <img
              src="/logo.png"
              alt=""
              style={{ filter: 'invert(1)', width: 150 }}
            />
          </h2>
          <br />
          <a className="footer__link tx-17" href="mailto:info@hotelytrip.com">
            <img
              src="/mail.svg"
              data-rd-image="./img/svg/mail.svg"
              width={24}
              height={24}
              alt="mail"
            />
            info@hotelytrip.com
          </a>
        </div>
        <div className="footer__right">
          <div className="footer__right-top">
            <p className="footer__right-text tx-17">
              Planujte svoje potovanje z nami in odkrijte najboljša prenočišča
              z edinstvenimi ugodnostmi. Uživajte v luksuzu, udobju in
              nepozabnih izkušnjah na vsakem koraku.
            </p>
          </div>
          <div className="footer__right-bottom">
            <ul
              className="footer__list"
              style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}
            >
              <li className="footer__item">
                <Link to="/privacy">
                  <a className="footer__item-link tx-17">Politika zasebnosti</a>
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/terms">
                  <a className="footer__item-link tx-17">Pogoji uporabe</a>
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/contact">
                  <a className="footer__item-link tx-17">Kontakt</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
