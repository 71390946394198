import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  const handleBurgerClick = () => {
    setIsMenuActive(true);
    document.body.style.overflow = 'hidden';
  };

  const handleMenuClose = () => {
    setIsMenuActive(false);
    document.body.style.overflow = '';
  };

  if (
    location.pathname === '/page/' ||
    location.pathname === '/page' ||
    location.pathname === '/page/contact/' ||
    location.pathname === '/page/contact' ||
    location.pathname === '/page/privacy/' ||
    location.pathname === '/page/privacy' ||
    location.pathname === '/page/terms/' ||
    location.pathname === '/page/terms'
  ) {
    return (
      <header className="header rd-upload rd-load">
        <div className="header__container container">
          <ul className="header__list">
            <li className="header__item">
              <Link to="/page">
                <img src="/logo.png" alt="Logotip" />
              </Link>
            </li>
          </ul>
          <button className="burger" onClick={handleBurgerClick}>
            <span />
            <span />
            <span />
          </button>
          <div className={`header__menu ${isMenuActive ? 'active' : ''}`}>
            <nav className="nav">
              <ul className="nav__list">
                <li className="nav__item">
                  <Link className="nav__link tx-20" to="/page" onClick={handleMenuClose}>
                    Domov
                  </Link>
                </li>
                <li className="nav__item">
                  <a className="nav__link tx-20" href="/page/#rest" onClick={handleMenuClose}>
                    O nas
                  </a>
                </li>
                <li className="nav__item">
                  <Link className="nav__link tx-20" to="/page/contact" onClick={handleMenuClose}>
                    Kontakt
                  </Link>
                </li>
              </ul>
            </nav>
            <button className="header__exit-menu" onClick={handleMenuClose}></button>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className="header rd-upload rd-load">
      <div className="header__container container">
        <ul className="header__list">
          <li className="header__item">
            <Link to="/">
              <img src="/logo.png" alt="Logotip" />
            </Link>
          </li>
        </ul>
        <button className="burger" onClick={handleBurgerClick}>
          <span />
          <span />
          <span />
        </button>
        <div className={`header__menu ${isMenuActive ? 'active' : ''}`}>
          <nav className="nav">
            <ul className="nav__list">
              <li className="nav__item">
                <Link className="nav__link tx-20" to="/" onClick={handleMenuClose}>
                  Domov
                </Link>
              </li>
              <li className="nav__item">
                <a className="nav__link tx-20" href="/#rest" onClick={handleMenuClose}>
                  O nas
                </a>
              </li>
              <li className="nav__item">
                <Link className="nav__link tx-20" to="/contact" onClick={handleMenuClose}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </nav>
          <button className="header__exit-menu" onClick={handleMenuClose}></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
