import React, { useState, useEffect } from 'react';

const HomepageP  = () => {
    return (
        <div>
            <main>
    <section className="block-welcome rd-upload rd-load">
        <div className="block-welcome__container container">
            <div className="block-welcome__wrapper-title">
                <h1 className="block-welcome__title tt-1">
                    Odkrijte svet luksuznih hotelov v Sloveniji
                </h1>
                <p className="block-welcome__text tx-17">
                    Načrtujte svoje popolno potovanje z Hotely Trip – vašim vodičem 
                    po najboljših hotelskih destinacijah po svetu. Uživajte v edinstvenih 
                    nastanitvah, luksuznih ugodnostih in nepozabnih izkušnjah, ki bodo 
                    vaše potovanje dvignile na višjo raven. Začnite svojo sanjsko avanturo še danes!
                </p>
            </div>
        </div>
    </section>
    <section className="about" id="rest">
        <div className="about__container container">
            <div className="about__left">
                <h2 className="about__title tt-2">O nas</h2>
                <p className="about__text tx-17">
                    Pri Hotely Tripu skrbno izbiramo najboljše hotelske destinacije po svetu 
                    in vam ponujamo celovit vodič do vrhunskih nastanitev, zanimivih dejavnosti 
                    in edinstvenih ugodnosti. Naše poslanstvo je postati vaš zaupanja vreden vir 
                    informacij in pomočnik pri načrtovanju popolnega dopusta, kjer lahko uživate 
                    v udobju, raziskovanju in luksuzu.
                    <br />
                    <br />
                    Odpravite se na potovanje, polno razkošja, avantur in sprostitve z Hotely Tripom. 
                    Naj bomo vaš zvesti sopotnik pri odkrivanju sveta hotelskih destinacij in ustvarjanju 
                    spominov, ki bodo trajali vse življenje.
                    <br />
                    <br />
                    Ne glede na to, ali ste izkušen popotnik ali iščete pobeg iz vsakdana, 
                    smo tukaj, da vaša potovanja naredimo nepozabna. 
                    <br />
                    <br />
                    Začnite načrtovati svojo naslednjo avanturo z Hotely Tripom še danes 
                    in dovolite nam, da postanemo del vaše edinstvene turistične zgodbe.
                </p>
            </div>
            <div className="about__img rd-upload rd-load">
                <img src="/img02.webp" alt="Restavracija" />
            </div>
        </div>
    </section>
    <section className="konferenc" id="com">
        <div className="konferenc__container container">
            <div className="konferenc__top">
                <h2 className="konferenc__title tt-2">
                    Seznam najboljših kazino hotelov v Sloveniji
                </h2>
            </div>
            <ul className="konferenc__list">
                <li className="konferenc__item img1">
                    <a href="https://www.booking.com/hotel/si/casino-resort-admiral.html">
                        <h3 className="konferenc__title2 tx-22">Casino & Hotel ADMIRAL Kozina</h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Bazoviška cesta 23, 6240 Kozina, Slovenija
                    </p>
                </li>
                <li className="konferenc__item img2">
                    <a href="https://www.booking.com/hotel/si/perla-casino.html">
                        <h3 className="konferenc__title2 tx-22">
                            Perla, Resort & Entertainment
                        </h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Kidričeva ulica 7, 5000 Nova Gorica, Slovenija
                    </p>
                </li>
                <li className="konferenc__item img3">
                    <a href="https://www.booking.com/hotel/si/park-casino.html">
                        <h3 className="konferenc__title2 tx-22">Park, Hotel & Entertainment</h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Delpinova 5, 5000 Nova Gorica, Slovenija
                    </p>
                </li>
                <li className="konferenc__item img4">
                    <a href="https://www.booking.com/hotel/si/safir-casino.html">
                        <h3 className="konferenc__title2 tx-22">Safir Hotel Casino</h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Partizanska cesta 149, 6210 Sežana, Slovenija
                    </p>
                </li>
                <li className="konferenc__item img5">
                    <a href="https://www.booking.com/hotel/si/atlantida-boutiqe.html">
                        <h3 className="konferenc__title2 tx-22">
                            Atlantida Boutique Hotel
                        </h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Cvetlični hrib 1, 3250 Rogaška Slatina, Slovenija
                    </p>
                </li>
                <li className="konferenc__item img6">
                    <a href="https://www.booking.com/hotel/si/grand-rogaska-premium.html">
                        <h3 className="konferenc__title2 tx-22">Grand Hotel Rogaška Premium</h3>
                    </a>
                    <p className="konferenc__text tx-17">
                        Zdraviliški trg 11, 3250 Rogaška Slatina, Slovenija
                    </p>
                </li>
            </ul>
        </div>
    </section>
    <section className="play" id="play">
        <div className="play__container container">
            <div className="play__cards">
                <div className="play__left">
                    <h2 className="play__title tt-1">Odkrijte Slovenijo z Hotely Tripom</h2>
                    <p className="play__text tx-17">
                        Slovenija je država bogate zgodovine, kulture in čudovitih destinacij, 
                        ki ponujajo nepozabna doživetja. Hotely Trip vam omogoča, da raziščete 
                        vse, kar Slovenija ponuja, od vrhunskih hotelov do edinstvenih lokalnih 
                        doživetij. Ne glede na to, ali iščete luksuzno nastanitev za poslovno potovanje 
                        ali popoln kraj za sprostitev, naša skrbno izbrana ponudba hotelov bo zadovoljila 
                        vse vaše potrebe. Prepustite organizacijo nam in uživajte v vsem, kar Slovenija 
                        ponuja, z udobjem in kakovostjo, ki vam jo zagotavlja Hotely Trip.
                    </p>
                </div>
                <div className="play__image rd-upload rd-load">
                    <img src="/img03.webp" alt="Igralni avtomati" />
                </div>
            </div>
        </div>
    </section>
</main>

        </div>
    );
};

export default HomepageP ;
