import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Homepage from './pages/Homepage';
import HomepageP from './pages/HomepageP';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyP from './pages/PrivacyPolicyP';
import Terms from './pages/Terms';
import TermsP from './pages/TermsP';
import Contact from './pages/Contact';
import ContactP from './pages/ContactP';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/page" element={<HomepageP />} />
            <Route path="/page/privacy" element={<PrivacyPolicyP />} />
            <Route path="/page/terms" element={<TermsP />} />
            <Route path="/page/contact" element={<ContactP />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
