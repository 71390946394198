import React from 'react';

const Terms = () => {
  return (
<main>
  <section className="block-welcome rd-upload rd-load">
    <div className="block-welcome__container container">
      <div className="block-welcome__wrapper-title">
        <h1 className="block-welcome__title tt-1">Pogoji uporabe</h1>
      </div>
    </div>
  </section>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .konferenc__top{\n          margin-top: 50px;\n        }\n        \n        p.tx-17{\n            margin-top: 10px;\n            line-height: 1.5;\n        }\n\n        h2.tx-17{\n            line-height: 1.5;\n            font-weight: 700;\n            font-size: 32px;\n            margin-top: 30px;\n        }\n      "
    }}
  />
  <section className="konferenc" id="com">
    <div className="konferenc__container container">
      <div className="konferenc__top">
        <p className="tx-17">
          Dobrodošli na spletno stran Hotely Trip. Z uporabo te strani
          se strinjate z naslednjimi pogoji uporabe. Prosimo, da te pogoje
          natančno preberete, preden uporabite našo spletno stran.
        </p>
        <h2 className="tx-17">Sprejemanje pogojev</h2>
        <p className="tx-17">
          Z uporabo te spletne strani potrjujete, da ste prebrali, razumeli in
          sprejeli te pogoje uporabe. Če se ne strinjate z nobenim delom teh
          pogojev, vas prosimo, da ne uporabljate naše spletne strani.
        </p>
        <h2 className="tx-17">Uporaba spletne strani</h2>
        <p className="tx-17">
          Vsebina na tej strani je namenjena izključno vaši osebni uporabi.
          Kopiranje, distribucija, sprememba ali objava vsebine brez predhodnega
          pisnega dovoljenja ni dovoljena.
        </p>
        <h2 className="tx-17">Omejitev odgovornosti</h2>
        <p className="tx-17">
          Hotely Trip ne bo odgovoren za kakršnokoli škodo, ki bi lahko nastala
          zaradi uporabe te spletne strani, vključno, vendar ne omejeno na,
          neposredno, posredno, naključno ali posledično škodo.
        </p>
        <h2 className="tx-17">Varovanje zasebnosti</h2>
        <p className="tx-17">
          Z uporabo naše spletne strani se strinjate z našo{" "}
          <a href="/privacy.html" className="tx-17">
            Politiko zasebnosti
          </a>{" "}
          , ki opisuje, kako zbiramo, uporabljamo in varujemo vaše osebne
          podatke.
        </p>
        <h2 className="tx-17">Spremembe pogojev</h2>
        <p className="tx-17">
          Hotely Trip si pridržuje pravico do spremembe teh pogojev uporabe kadarkoli.
          Spremembe začnejo veljati po objavi na tej strani. Prosimo, da redno
          preverjate pogoje uporabe, da boste obveščeni o morebitnih spremembah.
        </p>
        <h2 className="tx-17">Kontakt</h2>
        <p className="tx-17">
          Če imate kakršnakoli vprašanja glede teh pogojev uporabe, nas prosimo
          kontaktirajte prek e-pošte:{" "}
          <a className="tx-17" href="mailto:info@hotelytrip.com">
            info@hotelytrip.com
          </a>
          .
        </p>
      </div>
    </div>
  </section>
</main>
  );
};

export default Terms;
