import React from 'react';

const PrivacyPolicy = () => {
  return (
<main>
  <section className="block-welcome rd-upload rd-load">
    <div className="block-welcome__container container">
      <div className="block-welcome__wrapper-title">
        <h1 className="block-welcome__title tt-1">Politika zasebnosti</h1>
      </div>
    </div>
  </section>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .konferenc__top{\n          margin-top: 50px;\n        }\n        \n        p.tx-17{\n            margin-top: 10px;\n            line-height: 1.5;\n        }\n\n        h2.tx-17{\n            line-height: 1.5;\n            font-weight: 700;\n            font-size: 32px;\n            margin-top: 30px;\n        }\n      "
    }}
  />
  <section className="konferenc" id="com">
    <div className="konferenc__container container">
      <div className="konferenc__top">
        <p className="tx-17">
          Vaša zasebnost nam je izjemno pomembna. Ta politika zasebnosti
          pojasnjuje, katere podatke zbiramo, kako jih uporabljamo in kako
          ščitimo vašo zasebnost.
        </p>
        <h2 className="tx-17">Podatki, ki jih zbiramo</h2>
        <p className="tx-17">
          Zbiramo naslednje vrste podatkov, ko uporabljate našo storitev:
        </p>
        <ul className="tx-17">
          <li className="tx-17">
            <strong>Osebni podatki:</strong> Ime, priimek, e-poštni naslov,
            telefonska številka.
          </li>
          <li className="tx-17">
            <strong>Podatki o uporabi:</strong> Informacije o tem, kako
            uporabljate našo spletno stran, vključno z IP naslovom, vrsto
            brskalnika in naprave.
          </li>
        </ul>
        <h2 className="tx-17">Kako uporabljamo vaše podatke</h2>
        <p className="tx-17">Podatke zbiramo za namene:</p>
        <ul className="tx-17">
          <li className="tx-17">Omogočanja uporabe naših storitev.</li>
          <li className="tx-17">
            Komunikacije z vami, vključno s pošiljanjem promocijskih ponudb in
            informacij.
          </li>
          <li className="tx-17">
            Izboljšanja naših storitev in uporabniške izkušnje.
          </li>
        </ul>
        <h2 className="tx-17">Zaščita vaših podatkov</h2>
        <p className="tx-17">
          Sprejemamo ustrezne tehnične in organizacijske ukrepe za zagotavljanje
          varnosti vaših osebnih podatkov pred nepooblaščenim dostopom,
          spremembo ali izgubo.
        </p>
        <h2 className="tx-17">Deljenje podatkov</h2>
        <p className="tx-17">
          Vaših osebnih podatkov ne delimo s tretjimi osebami, razen kadar je to
          nujno za zagotavljanje naših storitev ali kadar je to zakonsko
          zahtevano.
        </p>
        <h2 className="tx-17">Vaše pravice</h2>
        <p className="tx-17">
          Imate pravico dostopati do svojih podatkov, jih popraviti, zahtevati
          njihovo izbris ali omejiti njihovo obdelavo. Prav tako lahko kadarkoli
          prekličete svojo privolitev.
        </p>
        <h2 className="tx-17">Kontakt</h2>
        <p className="tx-17">
          Če imate kakršnakoli vprašanja o naši politiki zasebnosti, nas prosimo
          kontaktirajte prek e-poštnega naslova: {" "}
          <a className="tx-17" href="mailto:info@hotelytrip.com">
            info@hotelytrip.com
          </a>
          .
        </p>
      </div>
    </div>
  </section>
</main>

  );
};

export default PrivacyPolicy;
